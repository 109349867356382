
import * as React from 'react';
import {Route, Routes, BrowserRouter, useNavigate, useParams} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {Component, useState, useEffect} from "react";
// import {Col, Container, CarouselItem, Carousel} from "react-bootstrap";
import {sections, ApiService, category_nav_items, Article} from "./services";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Image, Layout, Menu, theme, ConfigProvider, Row, MenuProps, Typography, Col, Carousel} from 'antd';
import "./input.css"

const { Title, Paragraph } = Typography;
const { Header, Content, Footer } = Layout;

let service = new ApiService()
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAX2yeTZy9PKphBMxwNxdqV81G7pCc3WVU",
  authDomain: "dosidi-blog.firebaseapp.com",
  projectId: "dosidi-blog",
  storageBucket: "dosidi-blog.appspot.com",
  messagingSenderId: "976616135975",
  appId: "1:976616135975:web:5481f5787b7aa87bd55d02",
  measurementId: "G-ETKF0QS7NY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const items_per_row = 3

const AdsComponent = (props) => {
    const { dataAdSlot } = props;  



    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

        catch (e) {
            console.log(e)
        }

    },[]);



    return (
        <div style={{width: "250px"}}>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8111331225497238"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
        </div>
    );
};

const RenderNavBar = () => {
    const navigate = useNavigate();
    const {
        token: { colorBgContainer, colorPrimary , colorFillSecondary},
      } = theme.useToken();


    return (
        <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', backgroundColor: colorBgContainer}}>
        <div
            style={{
              float: 'left',
              width: 120,
              height: 31,
              margin: '4px 15px 16px 0',
            }}
          >
        <Image width={"95%"} alt='dosidi logo' src="/logo_final.png"/>
        </div>
        <div style={{color: "white", display: "flex"}}>
            {category_nav_items.map(nav_content => (<a style={{marginRight: "7px"}} href={nav_content.link}>{nav_content.text}</a>))}
        </div>
        </Header>
    );
  };

  const RenderFooter = () => {

    return (
        <Footer style={{backgroundColor: '#9E339F', width: "100%"}}>
            <Row justify={"space-between"} style={{color: "white"}}>
                <a href='/policy'>Privacy Policy</a>
                <a href='https://www.facebook.com/profile.php?id=100086889373209'>Social Media</a>
                <a href='mailto:dosidiofficial@gmail.com'>Contact</a>
            </Row>
        </Footer>
    )
  }
  

let renderAd = (index, page_len) =>{
    /*if (index % 2 && index+1 != page_len){
        return (
            <div>
                <h1>
                    Adspace
                </h1>
            </div>
        )
    }*/
    return null
}

const Landing = () => {
    const {
      token: { colorBgContainer, colorPrimary , colorFillSecondary},
    } = theme.useToken();

    return (
      <Layout>
        <Content className="site-layout" style={{ padding: '0 30px', display: "flex", justifyContent: 'center', alignItems: 'center', }}>
          <div style={{ padding: 24, minHeight: 380, maxWidth: "850px"}}>
            <Title level={3} style={{}}>
            Welcome to Dosidi!
            </Title>
            <Paragraph style={{marginTop: "20px"}}>
            At Dosidi, we believe that fitness is for everyone, regardless of size. Our mission is to empower individuals of all body types to lead a healthy and active lifestyle.
            </Paragraph >
            <Paragraph >
            Our blog offers a wide range of information and resources to help you achieve your fitness goals, including: Workout plans and exercises tailored for small-sized individuals and Nutritional tips and meal planning ideas to support a healthy diet 
            Are you ready to get started on your fitness journey? Sign up for our newsletter and receive our latest articles, tips, and promotions straight to your inbox!
            </Paragraph >
            <Paragraph >
            Join our community of small-sized fitness enthusiasts today and take the first step towards a healthier, happier you!
            </Paragraph >
            <Paragraph style={{display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: "15px"}}>
            Follow us on social media for daily motivation and inspiration!
            </Paragraph >
          </div>
        </Content>
      </Layout>
    );
  };

  const Policy = () => {
    const {
      token: { colorBgContainer, colorPrimary , colorFillSecondary},
    } = theme.useToken();

    return (
      <Layout>
        <Content className="site-layout" style={{ padding: '0 30px', display: "flex", justifyContent: 'center', alignItems: 'center', }}>
          <div style={{ padding: 24, minHeight: 380, maxWidth: "850px"}}>
            <Title level={3} style={{}}>
            Privacy Policy
            </Title>
            <Paragraph style={{marginTop: "20px"}}>
            Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website or other websites.
            </Paragraph >
            <Paragraph style={{marginTop: "20px"}}>
            Google's use of advertising cookies enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.
            </Paragraph >
            <Paragraph style={{marginTop: "20px"}}>
            Users may opt out of personalized advertising by visiting <a href='https://www.google.com/settings/ads'>Ads Settings</a>.
            </Paragraph >
          </div>
        </Content>
      </Layout>
    );
  };
  

const sliceArticles = (articles) => {
    let sliced_articles = []
    let i = 0
    while (i < articles.length) {
        let chunk_size = items_per_row
        if (i % 2){
            chunk_size = items_per_row - 1
        }
        const chunk = articles.slice(i, i + chunk_size);
        sliced_articles.push(chunk)

        i += chunk_size
    }

    return sliced_articles 

}

const renderArticleCard = (article) => {
    return (
        <Col style={{borderStyle: "solid", marginRight: "2px", marginLeft: "2px", maxWidth: "300px"}}>
            <div >
                <Image src={article.image_link} alt={article.headline}/>
                <div style={{minHeight: "40px"}}>
                    <Title level={5} ><a href={article.getLink()}>{article.headline}</a></Title>
                </div>
            </div>
        </Col>
    )
}

const renderAdCard = (number_of_articles) => {
    if (number_of_articles === (items_per_row - 2)){
        return (
            <Col style={{borderStyle: "solid", marginRight: "2px", marginLeft: "2px", maxWidth: "300px"}}>
                <div style={{minHeight: "40px", minWidth: "250px", minHeight: "200px"}}>
                ADSPACE 
                </div>
            </Col>
        )
    }
    return null
}
const renderArticleRow = (articles, index_x) => {

    return (
        <Row style={{marginTop: "2px", display:'flex', justifyContent: 'center', alignItems: 'center'}} key={index_x}>
            {articles.map((article, index_y) => (
                renderArticleCard(article)
            ))}
        </Row>
    )
}

const CategoryLanding = () => {

    const [articles, setArticles] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        const getArticles = async (id) => {
            let result = await service.getAllArticlesForCategory(id)
            console.log("Called effect")
            console.log(result)
            if (result){
                setArticles(result)
            }
        }
        getArticles(id)

      }, [id])

    if (articles.length === 0){
        return (<div>loading...</div>)
    }

    const highlights = [...articles]
    const article_rows = sliceArticles([...articles])

    return(
            <Layout>
                <Content>
                    <Row>
                        <div style={{marginTop: "20px"}}></div>
                    </Row>
                
                    <Col >
                        <Carousel >
                            {highlights.map((article, high_index) => (
                            <div>
                                <div style={{justifyContent: 'center', alignItems: 'center', display: "flex"}}>
                                    <Image src={article.image_link} alt={article.headline} width={"500px"} style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}/>
                                </div>
                                <div style={{marginBottom: "20px", justifyContent: 'center', alignItems: 'center'}}>
                                    <Title level={3} style={{textAlign: "center"}}>
                                        <a href={article.getLink()}>{article.headline}</a>
                                    </Title>
                                    <p style={{textAlign: "center"}}>
                                        {article.description}
                                    </p>
                                </div>
                            </div>
                            ))}
                        </Carousel>
                        <Row style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            <div style={{marginTop: "15px"}}>

                            {article_rows.map((article_row, row_index) => renderArticleRow(article_row, row_index))}
                            </div>
                        </Row>
                        
                    </Col>
                </Content>
            </Layout>
    )
}

const ArticlePage = () => {

    const [ article, setArticle ] = useState(new Article("", "", "", "", "", "", ""))
    let { id } = useParams();
    useEffect(() => {
        service.getArticle(id).then(article_fetched => setArticle(article_fetched))
    }, [] )


    if (!article){
        return null
    }
    return (
                <Layout style={{marginBottom: "20px"}}>
                    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        <Row>
                            <Col style={{maxWidth: "650px"}}>
                                <Row style={{marginTop: "5px", justifyContent: 'center', alignItems: 'center'}}>
                                    <div style={{background: "rgb(187, 36, 102)", marginTop: "3px"}}>
                                        <div style={{marginLeft: "7px", marginRight: "7px", marginTop: "7px"}}>
                                            <Row>
                                                <Image src={article.image_link} alt={article.headline} width={"100%"}/>
                                            </Row>
                                            <Row>
                                                <Title style={{color: "white"}}>
                                                    {article.headline}
                                                    
                                                </Title>
                                            </Row>
                                            <Row>
                                                <Title level={4} style={{color: "white"}}>
                                                    {article.affiliate_link}
                                                </Title>
                                            </Row>

                                        </div>
            
                                    </div>
                                </Row>

                                <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: "8px", marginBottom: "8px", marginLeft: "7px", marginRight: "7px"}}>
                                    <div>
                                        <div>
                                            <Row style={{justifyContent: 'center', alignItems: 'center'}}>
                                                <Title level={3}>
                                                    {article.description}
                                                </Title >
                                                
                                            </Row>
                                            <AdsComponent dataAdSlot={"4987504079"}/>
                                        </div>
                                        {article.paragraphs.map((text, index) => 
                                        (
                                            <div>
                                                <Row style={{justifyContent: 'center', alignItems: 'center'}}>
                                                    <p>
                                                        {text}
                                                    </p>
                                                </Row>
                                                
                                            </div>
                                        ))}
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Layout>
    )

}
  
export default function App() {
    return (
        <BrowserRouter>
        <ConfigProvider
            theme={{
            token: {
                colorPrimary: '#ED4192',
                colorBgContainer: '#9E339F',
                colorFillSecondary: '#9E339F',
                borderRadius: '8px'
            },
            }}>
        <RenderNavBar/>
        <Routes>
        <Route exact path="/policy" element={<Policy/>}/>
        <Route exact path="/categories/:id" element={<CategoryLanding/>}/>
        <Route exact path="/articles/:id" element={<ArticlePage/>}/>
        <Route exact path="/" element={<Landing/>}/>

        </Routes>
        <RenderFooter/>
        </ConfigProvider>
        </BrowserRouter>
    );
    }
    
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(<App />);