
import axios from "axios";


class Subsection {
    constructor(name, link){
        this.name = name
        this.link = link

    }
}

class  Section extends Subsection{

    constructor(name, subsections, link){
        super(name, link)
        this.subsections = subsections
    }
}

class ListItem {
    constructor(item_name, item_description, image_link){
        this.item_name = item_name
        this.item_description = item_description
        this.image_link = image_link
    }
}

class BaseArticle {

    constructor(id, headline, description){
        this.headline = headline ? headline : ""
        this.description = description ? description : ""
        this.id = id
    }

    getLink(){
        return "/articles/" + this.id
    }
}
export class Article extends BaseArticle {
    constructor(paragraphs, description, headline, affiliate_link, review_items, image_link, id){
        super(id, headline, description)
        this.paragraphs = paragraphs ? paragraphs : []
        this.affiliate_link = affiliate_link ? affiliate_link : ""
        this.review_items = review_items ? review_items : []
        this.image_link = image_link ? image_link : ""
    }

}

class Listicle extends BaseArticle {
    constructor(headline, description, list_items, id){
        super(id, headline, description)
        this.list_items = list_items ? list_items : []
        this.image_link = list_items[0] ? list_items[0].image_link : undefined
    }

    getLink(){
        return "/lists/" + this.id
    }
}

export class NavItem {

    constructor(text, link){
        this.text = text
        this.link = link
    }
}

export let category_nav_items = [
    new NavItem("Diet", "/categories/diet"),
    new NavItem("Training", "/categories/training"), 
    new NavItem("Lifestyle", "/categories/lifestyle"),
    // new NavItem("Formats", "/categories/formats"),
    new NavItem("About", "/") 
]

export class ApiService {

    constructor(){
        if (window.location.hostname === "localhost"){
            this.url = "http://localhost:3001"
        }else{
            this.url = "https://us-central1-dosidi-blog.cloudfunctions.net/webApi/v1"
        }
    }

    getArticle(article){
        console.log(article)
        return axios.get(this.url + "/article/" + article).then(response => get_correct_object_type(response.data)).catch(err => {console.log(err)});
    }

    getAllArticlesForCategory(category){
        return axios.get(this.url + "/category/" + category).then(response => get_correct_obj_type_from_list(response.data)).catch(err => {console.log(err)});
    }
}

function get_correct_obj_type_from_list(list_of_objects){
    return list_of_objects.map(object_to_parse => get_correct_object_type(object_to_parse))
}

function get_correct_object_type(json_parsed){
    console.log(json_parsed.id)
    return new Article(json_parsed.paragraphs, json_parsed.description, json_parsed.headline, json_parsed.affiliate_link, json_parsed.review_items, json_parsed.image, json_parsed.id)
}

/*export let listicle = new Listicle("Funny money list",
  "blah blah blah paul allen blah blah dorsia",
    [
        new ListItem("Big cash", 
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/800/Lightning-McQueen.Cars.webp"),
        new ListItem("Ok cash", 
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        "https://lumiere-a.akamaihd.net/v1/images/open-uri20150608-27674-1eblt9q_d27f8dc6.jpeg"),
        new ListItem("Less cash", 
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        "https://static1.srcdn.com/wordpress/wp-content/uploads/2019/05/Cars-4-Lightning-McQueen.jpg"),
        new ListItem("No cash", 
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
        "https://preview.redd.it/wzug1r2avpu61.jpg?auto=webp&s=3912593c16d1429f9ff4068d4e8e9411517dcf71"),
    ]
   )*/

export let diet_subsections = [
    new Subsection("Supplements", "")
]
export let lifestyle_subsections = [
    new Subsection("Homegym", "")
]

export let training_subsections = [
    new Subsection("Martial Arts", ""),
    new Subsection("Programs", "")
]

export let sections = [
    new Section("Diet", diet_subsections, ""),
    new Section("Lifestyle", lifestyle_subsections, ""),
    new Section("Training", training_subsections, "")
]

/*export let article = new Article(
    [
        "here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        "here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
        "here are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."

    ],
    "Uaghhhhhhhhhhhhhhhhhhhh",
    "Funny money",
    "placeholder.affiliate.com",
    [
        ["basedness", "1000"],
        ["hotandtasty", "kida"],
        ["danger", "high"]
    ],
    "https://images.squarespace-cdn.com/content/v1/56410d89e4b08c5cf2d10fd8/1566929404220-0CL3B4WLQZT4YALPVEOF/LM+small.jpg"

)

export let list_test_data = [
    new ListItem("Big cash", 
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    "https://a1cf74336522e87f135f-2f21ace9a6cf0052456644b80fa06d4f.ssl.cf2.rackcdn.com/images/characters/large/800/Lightning-McQueen.Cars.webp"),
    new ListItem("Ok cash", 
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    "https://lumiere-a.akamaihd.net/v1/images/open-uri20150608-27674-1eblt9q_d27f8dc6.jpeg"),
    new ListItem("Less cash", 
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    "https://static1.srcdn.com/wordpress/wp-content/uploads/2019/05/Cars-4-Lightning-McQueen.jpg"),
    new ListItem("No cash", 
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters",
    "https://preview.redd.it/wzug1r2avpu61.jpg?auto=webp&s=3912593c16d1429f9ff4068d4e8e9411517dcf71"),
]

export let list_test_data_2 = [
    new ListItem(
        "Samsung Odyssey G7 C32G75T",
        "The Samsung Odyssey G7 brings an industry-leading combination with a resolution of 2560×1440 and a refresh rate of 240 Hz. Due to the VA panel, the G7 provides an incredible color reproduction with a deep black and a sharp and clear white color contrast. The color accuracy is perfect and you get plenty of options to tweak and adjust all the features to your own preferences. You get fast response and clear images with fantastic colors and details. It is the most slick and immersive display currently available with an excellent performance for all gaming genres.",
        "https://i.ytimg.com/vi/gEnd2fW9pwI/maxresdefault.jpg"

    ),
    new ListItem(
        "LG UltraGear 27GN950-B",
        "The LG UltraGear 27GN950-B delivers an impressive 144 Hz and an extremely low input delay. It also supports FreeSync and is G-Sync compatible. As one of the industry-leading displays, it doesn't come cheap, and you'll also need an industry-leading GPU for the many pixels to work properly. With a 27-inch IPS panel, the LG UltraGear 27GN950-B is a joy to look at. The LG UltraGear 27GN950-B brings rich and accurate colors, making this model perfect for working with movies and visual projects. The 27GN950-B has an elegant appearance with a matt black cover with nice red details. The stand feels solid and has a separate organizer for the cables which gives a tidy appearance. Overall, the LG UltraGear 27GN950-B manages the impossible by combining high resolution with excellent speed.",
        "https://i.ytimg.com/vi/gEnd2fW9pwI/maxresdefault.jpg"

    )
]*/



